.checkbox {
  display: none;
  &-icon {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: 2px solid $light-grayish-blue;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;

    @include responsive {
      width: 20px;
      height: 20px;
    }
  }
}

.checkbox:checked ~ .checkbox-icon {
  border: 2px solid transparent;
  background-image: url(../../assets/icon-check.svg),
    linear-gradient(to right, $check-bg-grad-1, $check-bg-grad-2);
  background-size: 60%, 150%;
  background-repeat: no-repeat, repeat;
  background-position: center;
}
